import React from "react";
import "./Header.scss";
import operatorLogo from "../../../assets/logos/operator-logo.png";
import { getEleAttribute } from "../utility";
const CustomThemeHeader = ({ theme }) => {
  return (
    <>
      <header
        className="header-border-mob"
        id="header"
        style={{
          background: getEleAttribute("customPrimaryColor", theme),
          borderBottom: `8px solid ${getEleAttribute(
            "btnBackgroundColor",
            theme
          )}`,
        }}
      >
        <a
          href="https://www.flyjetaccess.com/?gad_source=1&gclid=CjwKCAjwlbu2BhA3EiwA3yXyuwAHXV15W_iCVxTRsqUTE74jVRmEnAqSW8-e-0x4ox95QrBMrGSfoxoCcMgQAvD_BwE"
          target="_blank"
          rel="noreferrer"
        >
          <div className="logo">
            <img src={operatorLogo} alt="operator-logo" />
          </div>
        </a>
      </header>
    </>
  );
};

export default CustomThemeHeader;
