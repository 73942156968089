export const themecolors = {
  default: {
    defaultBackgroundColor: "#FFFFFF",
    defaultFontColor: "#000000",
    customPrimaryColor: "#838acb",
    customFontColor: "#FFFFFF",
    searchPanelBackgroundColor: "#F4F4F4",
    descriptionFontColor: "#2C3034",
    customSecondaryColor: "#F1F4F9",
    listItemFeatureFontColor: "#535A61",
    conciergeButtonBackgroundColor: "#B9A691",
  },
  "privateav-theme-00234532-sh34-3dbn": {
    defaultBackgroundColor: "#FFFFFF",
    defaultFontColor: "#000000",
    customPrimaryColor: "#694779",
    customFontColor: "#FFFFFF",
    searchPanelBackgroundColor: "#F4F4F4",
    descriptionFontColor: "#2C3034",
    customSecondaryColor: "#F1F4F9",
    listItemFeatureFontColor: "#535A61",
    conciergeButtonBackgroundColor: "#B9A691",
    // operatorLogoUrl: "http://localhost/logo.png",
    // defaultBackgroundColor: "#FFFFFF",
    // defaultFontColor: "#000000",
    // customPrimaryColor: "#000000",
    // customFontColor: "#FFFFFF",
    // searchPanelBackgroundColor: "#F4F4F4",
    // descriptionFontColor: "#2C3034",
    // customSecondaryColor: "#F1F4F9",
    // listItemFeatureFontColor: "#535A61",
    // conciergeButtonBackgroundColor: "#000000",
  },
};
