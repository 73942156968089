import React from 'react';
import "./PageContainer.scss";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";

function PageContainer({children, className, title, description, keyword, robots, viewport, charset}) {
  return (
    <div className={`PageContainer ${className}`}>
      {title && <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}/>
        <meta name="keywords" content={keyword}/>
        <meta name="robots" content={robots}/>
        <meta name="viewport" content={viewport}/>
        <meta name="charset" content={charset}/>
      </Helmet>}
      {children}
    </div>
  );
}

PageContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  keyword: PropTypes.string,
  robots: PropTypes.string,
  viewport: PropTypes.string,
  charset: PropTypes.string,
};
export default PageContainer;