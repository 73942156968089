import React from "react";
import "./ConciergeModal.scss";
import Modal from "../../../components/Modal";
import Mail from "./../../../assets/icons/talkToConcierge/mail.png";
import Phone from "./../../../assets/icons/talkToConcierge/phone.png";
function ConciergeModal({ status, onChange, existing = [] }) {
  return (
    <div className="ConciergeModal" onClick={() => onChange(false)}>
      <Modal close={() => onChange(false)} show={status}>
        <div className="ConciergeModalContainer">
          <p className="title">Flight Concierge</p>
          <h4 className="sub-title">
            Help for you 24/7. Jet Access is here <br />
            to help you with any questions you <br />
            may have.
          </h4>
          {/* <ConciergeList
            icon={<img src={Chat} style={{ height: "23px" }} />}
            content={"Live Chat Service"}
          /> */}
          <ConciergeList
            icon={<img src={Phone} alt="phone" />}
            content={"561.283.1214"}
            href={"tel:561.283.1214"}
          />
          <ConciergeList
            icon={<img src={Mail} alt="mail" />}
            content={"book@flyjetaccess.com"}
            href={"mailto:book@flyjetaccess.com"}
          />
        </div>
      </Modal>
    </div>
  );
}

const ConciergeList = ({ icon, content, href = "#", clickAction = null }) => {
  return (
    <div className="ConciergeListContainer">
      <div className="itemLeft">{icon}</div>
      <a
        href={href}
        onClick={() => {
          clickAction && clickAction();
        }}
        className="itemRight"
      >
        {content}
      </a>
    </div>
  );
};

export default ConciergeModal;
