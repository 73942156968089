import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div>
      <h1>Page Not Found</h1>
      <p>Redirecting you to the homepage...</p>
    </div>
  );
};

export default PageNotFound;
