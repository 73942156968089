import { themecolors } from "./themevariable";

let APIURL = "https://wl-dev-abone-api.airbookone.com/"; //Change to localhost if needed
let WIDGET_URL = "http://wl-devl.airbookone.com/ja";
let WIDGET_SCRIPT = "http://localhost:8080/build/ab1-widget.min.js";
let WIDGET_ID = "lo-operator-widget-identifier";
if (window.location.host === "abc.airbookone.com") {
  APIURL = "https://dev-abone-api.airbookone.com/";
  WIDGET_URL = "http://devl.airbookone.com/ja";
  WIDGET_SCRIPT = "https://devl.airbookone.com/ab1-widget.min.js";
  WIDGET_ID = "st-operator-widget-identifier";
}
if (window.location.host === "wl-abc.airbookone.com") {
  APIURL = "https://wl-dev-abone-api.airbookone.com/";
  WIDGET_URL = "http://wl-devl.airbookone.com/ja";
  WIDGET_SCRIPT = "https://wl-devl.airbookone.com/ab1-widget.min.js";
  WIDGET_ID = "wl-operator-widget-identifier";
}
if (window.location.host === "ja.airbookone.com") {
  APIURL = "https://api-v2.airbookone.com/";
  WIDGET_URL = "https://www.flyjetaccess.com/test-aircraft-charter/";
  WIDGET_SCRIPT = "https://partner.airbookone.com/ab1-widget.min.js";
  WIDGET_ID = "operator-widget-identifier";
}
if (window.location.host === "booking.flyjetaccess.com") {
  APIURL = "https://api-v2.airbookone.com/";
  WIDGET_URL = "https://booking.flyjetaccess.com/";
  WIDGET_SCRIPT = "https://partner.airbookone.com/ab1-widget.min.js";
  WIDGET_ID = "operator-widget-identifier";
}
export const API_PATH = APIURL;
export const WIDGET_PATH = WIDGET_URL;
export const WIDGET_SPATH = WIDGET_SCRIPT;
export const WIDGET_SID = WIDGET_ID;
export function validateDomain() {
  let loc = window.location;
  let pathname = loc.pathname;
  let redirectPath = localStorage.getItem("referralUrl")
    ? localStorage.getItem("referralUrl")
    : WIDGET_URL;
  if (
    loc.host === "abc.airbookone.com" ||
    loc.host === "wl-abc.airbookone.com" ||
    loc.host === "ja.airbookone.com" ||
    loc.host === "booking.flyjetaccess.com"
  ) {
    // Redirect immediately if the pathname does not include "choose-flight"
    if (!pathname.includes("/choose-flight/")) {
      window.location.replace(redirectPath);
      return false; // Prevent further execution
    } else {
      window.onbeforeunload = null;
      return true;
    }
  } else {
    if (!pathname.includes("/choose-flight/")) {
      window.location.replace(redirectPath);
      return false; // Prevent further execution
    } else {
      window.onbeforeunload = null;
      return true;
    }
  }
}

export function getUrlSegment(index) {
  let pathname = window.location.pathname;

  let segments = pathname.split("/");

  return segments[index] ? segments[index] : "";
}

export function getEleAttribute(attr, theme) {
  // let themeId = getUrlSegment(2) ? atob(getUrlSegment(2)) : "default";
  // theme = themecolors[themeId];
  let defaultTheme = themecolors.default;
  return theme && theme[attr] ? theme[attr] : defaultTheme[attr];
}

export function getSearchParams(name) {
  let searchVal = "";

  let search = window.location.search
    ? decodeURIComponent(window.location.search)
    : "";
  search = search.replace("?", "");

  let segments = search.split("&");

  let keyValArr = segments.filter((seg) => seg.startsWith(name));
  if (keyValArr.length > 0) {
    searchVal = keyValArr[0].replace(name + "=", "");
  }

  return searchVal;

  // return segments[index] ? segments[index] : '';
}

export function alphaSpaceOnly(str) {
  return /^[A-Za-z\s]*$/.test(str);
}

export function formatCurrency(amount, currency = "USD") {
  return new Intl.NumberFormat("en-US", {
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}
export function randomId(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
