import React from "react";
import PropTypes from "prop-types";

import "./Button.scss";
import { themecolors } from "../themevariable";
import { useState } from "react";

function Button({
  disabled = false,
  type = "black",
  onClick,
  label,
  fullWidth = true,
  size = "large",
  btnAction = "button",
  icon,
  iconPosition = "left",
  bgcolor = themecolors.customPrimaryColor,
  color = "#FFF",
  bordercolor = "",
  id = "",
  isSelected = false,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyles = {
    backgroundColor:
      (isHovered || isSelected) &&
      label === "Select Aircraft" &&
      label !== "Reserve" &&
      label !== "Talk to Concierge"
        ? color
        : bgcolor,
    color:
      (isHovered || isSelected) &&
      label === "Select Aircraft" &&
      label !== "Reserve" &&
      label !== "Talk to Concierge"
        ? bgcolor
        : color,
    border: `1px solid ${
      (isHovered || isSelected) &&
      label === "Select Aircraft" &&
      label !== "Reserve" &&
      label !== "Talk to Concierge"
        ? bgcolor
        : bordercolor
    }`,
  };
  return (
    <button
      disabled={disabled}
      className={`Button ${type}-btn ${
        fullWidth ? "fullWidth" : ""
      } ${size}-btn`}
      onClick={onClick}
      id={id}
      type={btnAction}
      style={buttonStyles}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon && iconPosition === "left" ? icon : ""} {label}{" "}
      {icon && iconPosition === "right" ? icon : ""}
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    "black",
    "yellow",
    "blue",
    "red",
    "link-blue",
    "outline-grey",
    "grey",
    "white-link",
    "white-blue",
    "link",
    "blue-link",
    "dark-grey",
    "white",
    "link-decoration",
    "red-disable",
    "warning-red",
  ]),
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(["extra-small", "small", "large"]),
  btnAction: PropTypes.oneOf(["submit", "button"]),
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(["left", "right"]),
};

export default Button;
