import React, { useEffect } from "react";
import "./styles.scss";
import operatorLogo from "./jet-access-logo.png";
import jetInterior from "./jet-interior.png";
import { WIDGET_SID, WIDGET_SPATH } from "../ChooseAFlight/utility";

const BookingWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = WIDGET_SID;
    script.type = "text/javascript";
    script.innerHTML = `
      (function (w, d, s, o, f, js, fjs) {
        w["SearchFlightsWidget"] = o;
        w[o] = w[o] || function () {
          (w[o].q = w[o].q || []).push(arguments);
        };
        (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
        js.id = o;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      })(
        window,
        document,
        "script",
        "nw",
        "${WIDGET_SPATH}"
      );
      nw(
        "init",
        {
          title: "Search Flights in world",
          btnAccentColor: "#008000",
          btnText: "Find A Plane",
          theme: "cHJpdmF0ZWF2LXRoZW1lLTAwMjM0NTMyLXNoMzQtM2Ribg==",
        },
        "show-widget-here"
      );
    `;
    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="landing-page">
      <div className="la-logo">
        <img src={operatorLogo} alt="Jet Access Logo" />
      </div>
      <div className="la-background-image">
        <img src={jetInterior} alt="Jet Interior" />
      </div>
      <div className="la-wrapper">
        <div className="la-content">
          <h1>
            We Elevate
            <br />
            Quality of Life
          </h1>
          <p>Experience the Jet Access Difference</p>
        </div>
        <div className="la-booking-form">
          <div id="show-widget-here"></div>
        </div>
      </div>
    </div>
  );
};

export default BookingWidget;
