import { createBrowserRouter } from "react-router-dom";
import ChooseAFlight from "./containers/ChooseAFlight";
import BookingWidget from "./containers/BookingWidget";
import PageNotFound from "./containers/PageNotFound";
import CustomThemeHeader from "./containers/ChooseAFlight/CustomThemeHeader";
import { useSelector } from "react-redux";

const BookingLayout = () => {
  return <BookingWidget />;
};

const FlightLayout = () => {
  const theme = useSelector((state) => state.widget.theme);

  return (
    <>
      <CustomThemeHeader theme={theme} />
      <ChooseAFlight />
    </>
  );
};
export const router = createBrowserRouter([
  {
    path: "/",
    element: <BookingLayout />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/choose-flight/:id",
    element: <FlightLayout />,
  },
]);
