import React, { useEffect, useState } from "react";
import ApplicationTab from "../../containers/ChooseAFlight/ApplicationTab";
import "./ChooseAFlight.scss";
import ChooseAFlightHeader from "./ChooseAFlightHeader";
import PageContainer from "../../components/SearchPageContainer";
import LoadingOverlay from "react-loading-overlay-ts";
import tabsData from "./ApplicationTab/tabsData.json";
import Vector32 from "../../assets/custom-theme/Vector32.png";
import Vector33 from "../../assets/custom-theme/Vector33.png";
import TripSummary from "./TripSummary";
import FlightListCard from "./FlightListCard";
import Gallery from "./Gallery";
import { getSessionID } from "../../utils/session.storage.utils";
import { API_PATH } from "./utility";
import LeftLine from "../../assets/custom-theme/Vector30.png";
import RightLine from "../../assets/custom-theme/Vector31.png";
import { getEleAttribute } from "./utility";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { handleQuoteReset } from "../../actions/widget.actions";

function ChooseAFlight() {
  const dispatch = useDispatch();
  const location = useLocation();
  const marketPlace = useSelector((state) => state.widget.marketplace);
  const theme = useSelector((state) => state.widget.theme);
  const quoteSuccess = useSelector((state) => state.widget.quoteSuccess);
  const operatorId = useSelector((state) => state.widget.operatorId);
  const [headerScroll, setHeaderScroll] = useState("default-header");
  const [isStickyTE, setIsStickyTE] = useState(false);
  let [currentJet, setCurrentJet] = useState("");
  let [gallery, setGallery] = useState(false);
  let [flightGallery, setFlightGallery] = useState(null);
  const [loader, setLoader] = useState(true);
  const [flight, setFlight] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedIndexes, setSelectedIndexes] = useState({});
  const [fixedctrcls, setFixedCls] = useState("");
  const [operatorKey, setOperatorKey] = useState("");
  const [flightType, setFlightType] = useState(null);
  const [currentBestTotal, setCurrentBestTotal] = useState(0);
  const [currentLowTotal, setCurrentLowTotal] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [tabChange, setTabChange] = useState(false);
  const [aircraftList, setAircraftList] = useState({
    midSizeJet: {
      best: [],
      low: [],
    },
    lightJet: {
      best: [],
      low: [],
    },
    turboProp: {
      best: [],
      low: [],
    },
    superMidSizeJet: {
      best: [],
      low: [],
    },
    largeJet: {
      best: [],
      low: [],
    },
    error: null,
  });

  const [currentFlightList, setFlightList] = useState({ best: [], low: [] });
  const [tabInfo, setTabInfo] = useState({});
  const [limit] = useState(20);
  const [isDownArrow, setIsDownArrow] = useState(true);
  const [conciergeModal, setConciergeModal] = useState(false);
  const [turboBestPage, setTurboBestPage] = useState(0);
  const [turboLowPage, setTurboLowPage] = useState(0);
  const [lightBestPage, setLightBestPage] = useState(0);
  const [lightLowPage, setLightLowPage] = useState(0);
  const [midBestPage, setMidBestPage] = useState(0);
  const [midLowPage, setMidLowPage] = useState(0);
  const [superMidBestPage, setSuperMidBestPage] = useState(0);
  const [superMidLowPage, setSuperMidLowPage] = useState(0);
  const [largeBestPage, setLargeBestPage] = useState(0);
  const [largeLowPage, setLargeLowPage] = useState(0);
  const [referralUrl, setReferralUrl] = useState("");
  useEffect(() => {
    let operatorkey = "";
    let searchparams = location.search;
    searchparams = searchparams.replace("?", "");
    let arr = searchparams.split("=");
    operatorkey = arr[1];
    // for (let i = 0; i < arr.length; i++) {
    //   let str = arr[i].split("=");
    //   if (str.length > 0) {
    //     if (str[0] === "operatorkey") {
    //       operatorkey = str[1];
    //     }
    //   }
    // }

    setOperatorKey(operatorkey);

    //setLoader(false);

    window.onbeforeunload = function () {
      return "Data will be lost if you leave the page, are you sure?";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (operatorKey !== "" && operatorId !== "") {
      getAirports();
    }
  }, [operatorKey, operatorId]);

  useEffect(() => {
    setCurrentJet("MIDSIZE_JET");
  }, []);

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 680 && fixedctrcls !== "tripsummary-fixed") {
        setFixedCls("tripsummary-fixed");
      }
      if (window.scrollY < 680 && fixedctrcls === "tripsummary-fixed") {
        setFixedCls("");
      }
    };
  });

  const getAirports = () => {
    if (!localStorage.getItem("acStat" + operatorKey)) {
      fetch(
        API_PATH +
          "white_label/get-aircraft-request-wl?operatorKey=" +
          operatorKey
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.hasOwnProperty("aircraftRequest")) {
            let params = {
              routeType: result.aircraftRequest.routeType,
              flyShare: result.aircraftRequest.flyShare,
              passengers: result.aircraftRequest.passengers,
              travelCities: result.aircraftRequest.travelCities.map((city) => ({
                origin: city.origin,
                destination: city.destination,
                departureTime: city.departureTime,
                returnTime: city.returnTime,
                order: city.order,
              })),
              petFriendly: result.aircraftRequest.petFriendly,
              sessionId: getSessionID(),
              operatorId: operatorId,
              affiliateId: result.aircraftRequest.affiliateId,
            };
            params.userId = "";
            params.email = result.email;
            getAircraftsList(params);
            setReferralUrl(result.referralUrl);
            localStorage.setItem("referralUrl", result.referralUrl);
            localStorage.setItem(
              "travelCities",
              JSON.stringify(result.aircraftRequest.travelCities)
            );
            localStorage.setItem("searchCriteria", JSON.stringify(params));
            localStorage.setItem("acStat" + operatorKey, true);
          } else {
            setLoader(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      let res = localStorage.getItem("searchCriteria");
      setReferralUrl(localStorage.getItem("referralUrl"));
      getAircraftsList(JSON.parse(res));
    }
  };

  const getAircraftsList = (params) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };

    fetch(API_PATH + "white_label/search-results-v2-wl", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        localStorage.setItem("acStat" + operatorKey, true);
        setAircraftList(result);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        // setLoader(false);
      });
  };

  const loadMoreAircrafts = (searchId, cat) => {
    let newPage = getPageOffset(cat);
    let params = {
      searchId: searchId,
      aircraftCategory: currentJet,
      listCategoryWL: cat,
      pageNumber: newPage,
      resultsPerPage: limit,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };

    // fetch(API_PATH + "white_label/aircraft-list-page-wl", requestOptions)
    const apiUrl = `${API_PATH}white_label/aircraft-list-page-wl?operatorId=${operatorId}`;
    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.hasOwnProperty("airCraftList")) {
          appendMoreAircrafts(result.airCraftList, cat, newPage);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const appendMoreAircrafts = (acList, cat, newPage) => {
    let existingAcList = aircraftList;
    let curKey = "";
    if (currentJet === "TURBO_PROP") {
      curKey = "turboProp";
      cat === "BEST" ? setTurboBestPage(newPage) : setTurboLowPage(newPage);
    } else if (currentJet === "LIGHT_JET") {
      curKey = "lightJet";
      cat === "BEST" ? setLightBestPage(newPage) : setLightLowPage(newPage);
    } else if (currentJet === "MIDSIZE_JET") {
      curKey = "midSizeJet";
      cat === "BEST" ? setMidBestPage(newPage) : setMidLowPage(newPage);
    } else if (currentJet === "SUPER_MIDSIZE_JET") {
      curKey = "superMidSizeJet";
      cat === "BEST"
        ? setSuperMidBestPage(newPage)
        : setSuperMidLowPage(newPage);
    } else if (currentJet === "LARGE_JET") {
      curKey = "largeJet";
      cat === "BEST" ? setLargeBestPage(newPage) : setLargeLowPage(newPage);
    }

    if (cat === "BEST") {
      existingAcList[curKey].best.concat(acList);
    } else if (cat === "LOW") {
      existingAcList[curKey].low.concat(acList);
    }

    Array.prototype.push.apply(
      existingAcList[curKey][cat.toLowerCase()],
      acList
    );
    setAircraftList(existingAcList);
  };
  const getPageOffset = (cat) => {
    let page = 0;
    if (currentJet === "TURBO_PROP") {
      page = cat === "BEST" ? turboBestPage : turboLowPage;
    } else if (currentJet === "LIGHT_JET") {
      page = cat === "BEST" ? lightBestPage : lightLowPage;
    } else if (currentJet === "MIDSIZE_JET") {
      page = cat === "BEST" ? midBestPage : midLowPage;
    } else if (currentJet === "SUPER_MIDSIZE_JET") {
      page = cat === "BEST" ? superMidBestPage : superMidLowPage;
    } else if (currentJet === "LARGE_JET") {
      page = cat === "BEST" ? largeBestPage : largeLowPage;
    }

    return parseInt(page) + 1;
  };

  const closeGallery = () => {
    setGallery(false);
  };

  const openGallery = (flightInfo) => {
    setFlightGallery(flightInfo);
    setGallery(true);
  };

  useEffect(() => {
    chooseTabInfo();
    chooseFlightList(currentJet);
  }, [currentJet, aircraftList]);

  const chooseTabInfo = () => {
    let tabInfoArr = tabsData.filter((tab) => tab.value === currentJet);
    tabInfoArr.length > 0 && setTabInfo(tabInfoArr[0]);
  };

  const selectFlight = (flightData, index, fType) => {
    if (window.innerWidth <= 767 && isDownArrow) setIsDownArrow(false);
    setFlight(flightData);
    setSelectedIndexes((prevIndexes) => ({
      ...prevIndexes,
      [currentJet]: {
        ...prevIndexes[currentJet],
        [fType]: { index },
      },
    }));
    setSelectedIndex(index);
    setFlightType(fType);
    dispatch(handleQuoteReset());
  };
  const onTabChange = (key) => {
    setConciergeModal(false);
    setIsDownArrow(true);
    setTabChange(false);
    setCurrentJet(key);
    dispatch(handleQuoteReset());
    const savedData = selectedIndexes[key];
    if (savedData) {
      const flightTypes = Object.keys(savedData);
      const savedFlightType = flightTypes[0];
      const savedIndex = savedData[savedFlightType]?.index || 0;
      if (savedIndex > 0) {
        setTabChange(true);
      } else if (savedIndex === 0) {
        setTabChange(false);
      }
      setFlightType(savedFlightType);
      setSelectedIndex(savedIndex);
    } else {
      setFlightType(null);
      setSelectedIndex(-1);
    }
  };
  useEffect(() => {
    const savedData = selectedIndexes[currentJet];
    if (savedData) {
      if (savedData.marketplace) {
        setFlight(currentFlightList?.low[selectedIndex]);
      } else if (savedData.premium) {
        setFlight(currentFlightList?.best[selectedIndex]);
      }
    }
  }, [currentFlightList]);

  function chooseFlightList(jetType) {
    let flightList = { best: [], low: [] };
    if (jetType === "TURBO_PROP") {
      flightList = aircraftList?.turboProp;
    } else if (jetType === "LIGHT_JET") {
      flightList = aircraftList?.lightJet;
    } else if (jetType === "MIDSIZE_JET") {
      flightList = aircraftList?.midSizeJet;
    } else if (jetType === "SUPER_MIDSIZE_JET") {
      flightList = aircraftList?.superMidSizeJet;
    } else if (jetType === "LARGE_JET") {
      flightList = aircraftList?.largeJet;
    }
    setFlightList(flightList);
    if (
      flightList &&
      flightList.best &&
      flightList.best.length > 0 &&
      (flightType === "premium" || flightType === null) &&
      selectedIndex === 0
    ) {
      setFlightType("premium");
      setFlight(flightList.best[0]);
    } else if (
      flightList &&
      flightList.low &&
      flightList.low.length > 0 &&
      (flightType === "marketplace" || flightType === null) &&
      selectedIndex === 0
    ) {
      setFlightType("marketplace");
      setFlight(flightList.low[0]);
    } else {
      setFlight(null);
    }
  }
  useEffect(() => {
    if (currentFlightList !== null) {
      setCurrentBestTotal(currentFlightList?.totalElementsInBest);
      setCurrentLowTotal(currentFlightList?.totalElementsInLow);
    }
  }, [currentFlightList]);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 850);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // useEffect(() => {
  //   const savedIndex = selectedIndexes[currentJet];
  //   if (savedIndex !== undefined) {
  //     const selectedElement = document.getElementById(`p_${savedIndex}_item`);
  //     if (selectedElement) {
  //       selectedElement.scrollIntoView({ behavior: "smooth", block: "center" });
  //     }
  //   }
  // }, [currentJet, selectedIndexes]);

  const listenScrollEvent = (event) => {
    if (window.scrollY > 455) {
      setIsStickyTE(true);
    }
    if (window.scrollY < 455) {
      setIsStickyTE(false);
    }
    if (window.scrollY < 73) {
      return setHeaderScroll("default-header");
    } else if (window.scrollY > 70) {
      return setHeaderScroll("sroll-header");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  useEffect(() => {
    if (isStickyTE && !isDownArrow) {
      setIsDownArrow(true);
    }
  }, [isStickyTE]);
  return (
    <PageContainer>
      <div className="ChooseAFlight">
        <div className={`flight-sub-div ${headerScroll}`}>
          <ChooseAFlightHeader
            searchOptions={{}}
            theme={theme}
            referralUrl={referralUrl}
          />
          <div className="trip-estimate-top">
            {isStickyTE &&
              !(
                marketPlace === false && currentFlightList?.best?.length === 0
              ) &&
              aircraftList.error === null && (
                <div
                  className={
                    isSmallScreen
                      ? "col-sm-12 trip-summary trip-summary-padding-style"
                      : "col-sm-12 trip-summary details-container-mob-view"
                  }
                >
                  <div>
                    <div className="details-container">
                      <div className="info-container">
                        <TripSummary
                          className="tripSummary"
                          flightData={flight}
                          theme={theme}
                          quoteSaved={quoteSuccess}
                          isSmallScreen={isSmallScreen}
                          isDownArrow={isDownArrow}
                          setIsDownArrow={setIsDownArrow}
                          conciergeModal={conciergeModal}
                          setConciergeModal={setConciergeModal}
                        ></TripSummary>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        <ApplicationTab
          tabs={tabsData}
          onTabChange={onTabChange}
          activeTab={"MIDSIZE_JET"}
          theme={theme}
        />
        <div className="flight-info">
          <div className=" row jet-img">
            {tabInfo?.heroImage && <img src={tabInfo.heroImage} alt="Hero" />}
          </div>
          {(currentFlightList?.best?.length > 0 || loader === true) && (
            <div className="jet-desc">
              <div className="desc-header">
                <div className="desc-header-left">
                  <img src={LeftLine} alt="lline" />
                </div>
                <div className="desc-header-mid">{tabInfo?.distance}</div>
                <div className="desc-header-right">
                  <img src={RightLine} alt="rline" />
                </div>
              </div>
              <div className="desc-body">
                <span
                  className={
                    tabInfo?.label === "Super Midsize" ? "first-line" : ""
                  }
                  dangerouslySetInnerHTML={{ __html: tabInfo?.description }}
                ></span>
                <div className="desc-feat">
                  <div className="f-icons">
                    <img src={Vector33} alt="" />
                    <span>{tabInfo?.passengerRange}</span>
                  </div>
                  <div className="f-icons">
                    <img src={Vector32} alt="" />
                    <span>{tabInfo?.luggageRange}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!(marketPlace === false && currentFlightList?.best?.length === 0) &&
          aircraftList.error === null && (
            <div
              className={
                isSmallScreen
                  ? "col-sm-12 trip-summary trip-summary-padding-style"
                  : "col-sm-12 trip-summary details-container-mob-view"
              }
            >
              <div>
                <div className="details-container">
                  <div className="info-container">
                    <TripSummary
                      className="tripSummary"
                      flightData={flight}
                      theme={theme}
                      quoteSaved={quoteSuccess}
                      isSmallScreen={isSmallScreen}
                      isDownArrow={isDownArrow}
                      setIsDownArrow={setIsDownArrow}
                      conciergeModal={conciergeModal}
                      setConciergeModal={setConciergeModal}
                    ></TripSummary>
                  </div>
                </div>
              </div>
            </div>
          )}
        <div className="container search-res-container ">
          <div className="row">
            <div className={isSmallScreen ? "col-sm-12" : "col-sm-8"}>
              <div className="res-main pad-0 bot-border">
                {currentFlightList?.best?.length > 0 && (
                  <div className="row row-cols-1 header-sec">
                    <div className="col pad-0 mobile-padding-30">
                      <h2>Premium Availability</h2>
                    </div>
                    {currentFlightList &&
                      currentFlightList.hasOwnProperty("best") &&
                      currentFlightList.best.length > 0 && (
                        <div className="col-sm-12 mob-margin-top">
                          <div className="small-text pad-0 col-sm-7">
                            Select an aircraft to view your estimated total.
                          </div>
                          <div className="small-text right pad-0 col-sm-5 mob-top-pad">
                            <span>Sorted By:</span> lowest to highest price
                          </div>
                        </div>
                      )}
                  </div>
                )}
                <div className="row mob-margin-top">
                  <div className="col-sm-12">
                    <div className="row mob-row-margin">
                      {currentFlightList &&
                        currentFlightList.hasOwnProperty("best") &&
                        currentFlightList.best.length > 0 &&
                        currentFlightList.best.map((flights, i) => (
                          <FlightListCard
                            key={`p_${i}_item`}
                            data={flights}
                            index={i}
                            openGallery={openGallery}
                            selectFlight={selectFlight}
                            selIndex={selectedIndex}
                            theme={theme}
                            fType="premium"
                            selectedFlightType={flightType}
                            tabChange={tabChange}
                            resetTripSummary={() =>
                              dispatch(handleQuoteReset())
                            }
                            isDownArrow={isDownArrow}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {currentBestTotal > currentFlightList?.best?.length && (
                <div className="row mar-0 ld_mr">
                  <div className="col-sm-12 load_btn">
                    <button
                      type="button"
                      className="loadMore"
                      onClick={() => {
                        setLoader(true);
                        loadMoreAircrafts(
                          currentFlightList.best[0].searchId,
                          "BEST"
                        );
                      }}
                      style={{
                        color: getEleAttribute("btnBackgroundColor", theme),
                        borderColor: getEleAttribute(
                          "btnBackgroundColor",
                          theme
                        ),
                      }}
                    >
                      Load More (
                      {currentBestTotal - currentFlightList?.best?.length})
                    </button>
                  </div>
                  <div className="col-sm-4"></div>
                </div>
              )}
              {marketPlace === true && (
                <>
                  <div color="col-sm-12">
                    {" "}
                    <hr className="ruler-mid ruler-mid-view" />
                  </div>
                  <div
                    className={
                      currentFlightList.low.length > 0
                        ? "res-main pad-0 top-pad"
                        : "res-main pad-0 empty-pad"
                    }
                  >
                    <div className="row row-cols-1 header-sec">
                      <div className="col pad-0">
                        <h2>Market Availability</h2>
                      </div>
                      {currentFlightList &&
                        currentFlightList.hasOwnProperty("low") &&
                        currentFlightList.low.length > 0 && (
                          <div className="col-sm-12">
                            <div className="small-text pad-0 col-sm-7">
                              Select an aircraft to view your estimated total.
                            </div>
                            <div className="small-text right pad-0 col-sm-5">
                              <span>Sorted By:</span> lowest to highest price
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row">
                          {currentFlightList &&
                            currentFlightList.hasOwnProperty("low") &&
                            currentFlightList.low.length > 0 &&
                            currentFlightList.low.map((flights, i) => (
                              <FlightListCard
                                key={`m_${i}_item`}
                                data={flights}
                                index={i}
                                openGallery={openGallery}
                                selectFlight={selectFlight}
                                selIndex={selectedIndex}
                                theme={theme}
                                fType="marketplace"
                                selectedFlightType={flightType}
                                tabChange={tabChange}
                                isDownArrow={isDownArrow}
                              />
                            ))}

                          {aircraftList.error !== null && (
                            <h3 className="no-flight-msg">
                              {aircraftList.error}
                            </h3>
                          )}
                          {currentFlightList &&
                            currentFlightList.hasOwnProperty("low") &&
                            currentFlightList.low.length === 0 && (
                              <h3 className="no-flight-msg">
                                There are no flights available
                              </h3>
                            )}
                        </div>
                      </div>
                    </div>

                    {currentLowTotal > currentFlightList?.low?.length && (
                      <div className="row mar-0 ld_mr">
                        <div className="col-sm-12 load_btn">
                          <button
                            type="button"
                            className="loadMore"
                            style={{
                              color: getEleAttribute(
                                "btnBackgroundColor",
                                theme
                              ),
                              borderColor: getEleAttribute(
                                "btnBackgroundColor",
                                theme
                              ),
                            }}
                            onClick={() => {
                              setLoader(true);
                              loadMoreAircrafts(
                                currentFlightList.low[0].searchId,
                                "LOW"
                              );
                            }}
                          >
                            Load More (
                            {currentLowTotal - currentFlightList?.low?.length})
                          </button>
                        </div>
                        <div className="col-sm-4"></div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {isSmallScreen
              ? ""
              : !(
                  marketPlace === false && currentFlightList?.best?.length === 0
                ) &&
                aircraftList.error === null && (
                  <div className="col-sm-4 res-summary-margin">
                    <div className={`res-summary ${fixedctrcls}`}>
                      <div
                        className={`details-container ${
                          fixedctrcls === "tripsummary-fixed"
                            ? ""
                            : "details-container-position"
                        }`}
                      >
                        <div className="info-container">
                          <TripSummary
                            className="tripSummary"
                            flightData={flight}
                            theme={theme}
                            quoteSaved={quoteSuccess}
                            isDownArrow={isDownArrow}
                            setIsDownArrow={setIsDownArrow}
                            conciergeModal={conciergeModal}
                            setConciergeModal={setConciergeModal}
                          ></TripSummary>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
          </div>
        </div>
        <div className="container ">
          <div className="row">
            <div className="col-sm-12">
              {currentFlightList &&
                currentFlightList.hasOwnProperty("best") &&
                currentFlightList.best.length === 0 &&
                loader === false && (
                  <>
                    {/* {isSmallScreen && (
                      <div
                        className={`res-summary ${fixedctrcls}`}
                        style={{ marginBottom: "10px" }}
                      >
                        <div className={`details-container`}>
                          <div className="info-container">
                            <TripSummary
                              className="tripSummary"
                              flightData={flight}
                              theme={theme}
                              quoteSaved={quoteSuccess}
                              isDownArrow={isDownArrow}
                              setIsDownArrow={setIsDownArrow}
                              conciergeModal={conciergeModal}
                              setConciergeModal={setConciergeModal}
                              isUnavailable={true}
                            ></TripSummary>
                          </div>
                        </div>
                      </div>
                    )} */}
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="unavailable-box">
                          <div className="unavailable-message unvailable-message-header">
                            <BsFillExclamationTriangleFill
                              className="exclamation-icon"
                              color={"#f2f2f2"}
                              size={25}
                            />
                            <h3>Unavailable</h3>
                          </div>
                          {/* <div>
                            <p className="info-text">
                              <BsFillExclamationTriangleFill
                                className="exclamation-icon"
                                color={"#f2f2f2"}
                                size={20}
                              />
                              Based on your search criteria and availability,
                              there are <br className="hide-on-small-screens" />
                              currently no aircraft in this category.
                            </p>
                            <p className="info-text">
                              Please Request Trip and we will reach out shortly,
                              with options
                              <br className="hide-on-small-screens" /> that may
                              include Empty Leg Opportunities.
                            </p>
                          </div> */}

                          {/* old code need to upate after API change */}
                          <div>
                            <p className="info-text">
                              Based on your search criteria and availability,
                              there are currently no aircraft in this category.
                            </p>
                            <p className="info-text">
                              Please contact us if you have a specific
                              aircraft/category need for this trip or refine
                              your search.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* {isSmallScreen ? (
                        ""
                      ) : (
                        <div className="col-sm-12 col-md-12 col-lg-4">
                          <div className={`res-summary ${fixedctrcls}`}>
                            <div className={`details-container`}>
                              <div className="info-container">
                                <TripSummary
                                  className="tripSummary"
                                  flightData={flight}
                                  theme={theme}
                                  quoteSaved={quoteSuccess}
                                  isDownArrow={isDownArrow}
                                  setIsDownArrow={setIsDownArrow}
                                  conciergeModal={conciergeModal}
                                  setConciergeModal={setConciergeModal}
                                  isUnavailable={true}
                                ></TripSummary>
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </>
                )}
              {aircraftList.error !== null && (
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="unavailable-box">
                    <div className="unavailable-message unvailable-message-header">
                      <BsFillExclamationTriangleFill
                        className="exclamation-icon"
                        color={"#f2f2f2"}
                        size={25}
                      />
                      <h3>Unavailable</h3>
                    </div>
                    <div>
                      <p className="info-text aircraftlisterror">
                        {aircraftList.error}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <LoadingOverlay
        active={loader}
        spinner
        text="Loading your content..."
        fadeSpeed="1000"
      />
      {flightGallery !== null && (
        <Gallery
          show={gallery}
          closeGallery={closeGallery}
          flight={flightGallery}
        />
      )}
    </PageContainer>
  );
}

export default ChooseAFlight;
