import React from "react";
import { AiOutlineCloseCircle, AiFillCloseCircle } from "react-icons/ai";
import "./Modal.scss";

function Modal({ close, show, children, width, center, gallery = false }) {
  const divStyle = { display: show ? "block" : "none" };
  return (
    <div className={"Modal"}>
      <div
        className="modal"
        // onClick={close}
        style={divStyle}
      >
        {!gallery && (
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {close && (
              <div className="close" onClick={close}>
                <AiOutlineCloseCircle size={30} />
              </div>
            )}
            {children}
          </div>
        )}
        {gallery && (
          <div className="gallery-content" onClick={(e) => e.stopPropagation()}>
            {close && (
              <div className="close" onClick={close}>
                <AiFillCloseCircle color={"#FFF"} size={30} />
              </div>
            )}
            {children}
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;
