// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageContainer {
  animation: fadeIn 0.5s;
}

@media screen and (max-width: 554px) {
  .PageContainer {
    margin-bottom: 90px;
  }
}
@media screen and (min-width: 554px) and (max-width: 700px) {
  .PageContainer {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 700px) and (max-width: 800px) {
  .PageContainer {
    margin-bottom: 150px;
  }
}
@media screen and (min-width: 801px) and (max-width: 890px) {
  .PageContainer {
    margin-bottom: 300px;
  }
}
@media screen and (min-width: 891px) and (max-width: 1024px) {
  .PageContainer {
    margin-bottom: 30rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SearchPageContainer/PageContainer.scss"],"names":[],"mappings":"AAEA;EAEE,sBAAA;AADF;;AAGA;EACE;IACE,mBAAA;EAAF;AACF;AAEA;EACE;IACE,oBAAA;EAAF;AACF;AAEA;EACE;IACE,oBAAA;EAAF;AACF;AAEA;EACE;IACE,oBAAA;EAAF;AACF;AAEA;EACE;IACE,oBAAA;EAAF;AACF","sourcesContent":["@use \"src/styles/variables\" as vars;\n\n.PageContainer {\n  -webkit-animation: fadeIn 0.5s;\n  animation: fadeIn 0.5s;\n}\n@media screen and (max-width: 554px) {\n  .PageContainer {\n    margin-bottom: 90px;\n  }\n}\n@media screen and (min-width: 554px) and (max-width: 700px) {\n  .PageContainer {\n    margin-bottom: 100px;\n  }\n}\n@media screen and (min-width: 700px) and (max-width: 800px) {\n  .PageContainer {\n    margin-bottom: 150px;\n  }\n}\n@media screen and (min-width: 801px) and (max-width: 890px) {\n  .PageContainer {\n    margin-bottom: 300px;\n  }\n}\n@media screen and (min-width: 891px) and (max-width: 1024px) {\n  .PageContainer {\n    margin-bottom: 30rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
