import React, { useEffect, useRef } from "react";
import Button from "../Button";
import "./FlightListCard.scss";
import moment from "moment";
import collections from "../../../assets/custom-theme/gallery-icon.svg";
import smallflight from "../../../assets/custom-theme/no-photos-def.png";
import Vector2 from "../../../assets/custom-theme/Vector2.png";
import Vector4 from "../../../assets/custom-theme/Vector4.png";
import Wifi from "../../../assets/custom-theme/wifi.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { getEleAttribute, formatCurrency } from "../utility";

const PopoverTop = React.forwardRef(
  ({ popper, children, show: _, ...props }, ref) => {
    let content = props.content;
    let safety_rating = "";
    let safetyRatings = [];
    if (
      content.hasOwnProperty("safetyRatings") &&
      content.safetyRatings !== null &&
      content.safetyRatings.length > 0
    ) {
      safetyRatings = content.safetyRatings || [];
      for (var i = 0; i < safetyRatings.length; i++) {
        if (
          i > 0 &&
          (safetyRatings[i].standard !== null ||
            safetyRatings[i].levelDisplayName !== null) &&
          safety_rating !== ""
        ) {
          safety_rating += ", ";
        }
        if (
          safetyRatings[i].standard !== null ||
          safetyRatings[i].levelDisplayName !== null
        ) {
          safety_rating +=
            (safetyRatings[i].standard !== null
              ? safetyRatings[i].standard
              : "") +
            " " +
            (safetyRatings[i].levelDisplayName !== null
              ? safetyRatings[i].levelDisplayName
              : "");
        }
        safety_rating = safety_rating.replace(/,\s*$/, "");
      }
    }
    return (
      <Popover title="Popover top" ref={ref} {...props}>
        <div className="popover-con">
          <div className="pop-top">
            <div className="popover-title">{content.modelDescription}</div>
          </div>
          <div className="pop-body">
            {content.hasOwnProperty("maxPassengers") && (
              <div className="details-item">
                <label className="lbltxt">PaxMax:</label>
                <span className="lblval">{content.maxPassengers}</span>
              </div>
            )}
            {content.hasOwnProperty("yearOfRefurb") && (
              <div className="details-item">
                <label className="lbltxt">Refurb date:</label>
                <span className="lblval">
                  {content.yearOfRefurb ? content.yearOfRefurb : "NA"}
                </span>
              </div>
            )}
            {content.hasOwnProperty("maxLuggages") && (
              <div className="details-item">
                <label className="lbltxt">Luggage limit:</label>
                <span className="lblval">{content.maxLuggages}</span>
              </div>
            )}
            {/* <div className="col-sm-1"></div> */}
            {content.hasOwnProperty("safetyRatings") && (
              <div className="details-item">
                <label className="lbltxt">Safety rating:</label>
                <span className="lblval" style={{ width: "100%" }}>
                  {safety_rating ? safety_rating : "NA"}
                </span>
              </div>
            )}
            {content.hasOwnProperty("amenities") && (
              <div className="details-item">
                <label className="lbltxt">Lavatory:</label>
                <span className="lblval">
                  {content.lavatory === true ? "Yes" : "No"}
                </span>
              </div>
            )}
            <div className="details-item">
              <label className="lbltxt">Mileage range:</label>
              <span className="lblval">
                {content.mileage_range ? content.mileage_range : "NA"}
              </span>
            </div>
            {/* )} */}
            {content.hasOwnProperty("petFriendly") && (
              <div className="details-item">
                <label className="lbltxt">Pet:</label>
                <span className="lblval">
                  {content.petFriendly === true ? "Yes" : "No"}
                </span>
              </div>
            )}
            <div className="details-item">
              <label className="lbltxt">Fuel type:</label>
              <span className="lblval">
                {content.fuel_type ? content.fuel_type : "NA"}
              </span>
            </div>
            {/* )} */}
            {content.hasOwnProperty("amenities") && (
              <div className="details-item">
                <label className="lbltxt">Wifi:</label>
                <span className="lblval">
                  {content.wiFi === true ? "Yes" : "NA"}
                </span>
              </div>
            )}
            {content.abonePriceBreakup.hasOwnProperty("carbonOffset") && (
              <div className="details-item">
                <label className="lbltxt">Carbon offset:</label>
                <span className="lblval">
                  {content.abonePriceBreakup.carbonOffset > 0 ? "Yes" : "NA"}
                </span>
              </div>
            )}
          </div>
        </div>
      </Popover>
    );
  }
);

function FlightListCard(props) {
  let Flightdata = props.data;
  let photos = Flightdata.tailPhotos || [];

  let flImg = smallflight;
  if (photos.length > 0) {
    flImg = photos[0];
  }
  const cardRef = useRef(null);
  useEffect(() => {
    const scrollCardIntoView = () => {
      cardRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    };

    const shouldScroll =
      (props.tabChange &&
        props.selIndex === props.index &&
        props.selectedFlightType === props.fType) ||
      (props.selIndex === 0 && props.index === 0);

    if (shouldScroll) {
      setTimeout(scrollCardIntoView, 200);
    }
  }, [
    props.selIndex,
    props.index,
    props.selectedFlightType,
    props.fType,
    props.tabChange,
    props.data.aircraftCategory,
  ]);

  return (
    <div
      className="col-sm-6 FlightListCard"
      key={"fl_" + props.index + props.data.aircraftCategory}
      ref={cardRef}
    >
      <div className="ibox">
        <div
          className={`ibox-content product-box ${
            props.selIndex === props.index &&
            props.selectedFlightType === props.fType
              ? "active"
              : ""
          }`}
          style={{
            border:
              props.selIndex === props.index &&
              props.selectedFlightType === props.fType
                ? `2px solid ${getEleAttribute(
                    "btnBackgroundColor",
                    props.theme
                  )}`
                : "",
          }}
        >
          <div className="product-header">
            {photos.length > 0 && (
              <div
                className="header-img-2"
                onClick={() => props.openGallery(Flightdata)}
              >
                <img role="img" src={collections} alt="" />
                <span
                  className="letter-spacing-style"
                  style={{
                    color: getEleAttribute("btnBackgroundColor", props.theme),
                  }}
                >
                  Photos
                </span>
              </div>
            )}
          </div>
          <div className="product-imitation">
            <img role="img" src={flImg} alt="" />
          </div>
          <div className="product-desc">
            {Flightdata.hasOwnProperty("modelDescription") &&
              Flightdata.modelDescription !== null && (
                <div
                  className="product-name"
                  style={{
                    color: getEleAttribute("defaultFontColor", props.theme),
                  }}
                >
                  {" "}
                  {Flightdata.modelDescription}
                </div>
              )}
            <div className="product-deatils">
              {Flightdata.hasOwnProperty("maxPassengers") && (
                <div className="product-deatils-col">
                  <span>
                    <img
                      role="img"
                      src={Vector2}
                      className="max-passengers-width"
                      alt=""
                    />
                  </span>
                  {Flightdata.maxPassengers}
                </div>
              )}
              {Flightdata.hasOwnProperty("yearOfRefurb") && (
                <div className="product-deatils-col">
                  <span className="span-margin-style">YOR </span>
                  {Flightdata.yearOfRefurb !== null &&
                  Flightdata.yearOfRefurb !== ""
                    ? moment(Flightdata.yearOfRefurb).format("YYYY")
                    : "NA"}
                </div>
              )}
              {Flightdata.hasOwnProperty("amenities") && (
                <div className="product-deatils-col max-passengers-span-style">
                  <span>
                    <img
                      role="img"
                      src={Wifi}
                      style={{ width: "100%" }}
                      className="wifi-icon-width"
                      alt=""
                    />
                  </span>
                  {Flightdata.wiFi === true ? "Yes" : "NA"}
                </div>
              )}
              {Flightdata.hasOwnProperty("calculatedPrice") && (
                <div className="product-deatils-col currency-img-padding">
                  <span className="currency-vector-mob-margin">
                    <img role="img" src={Vector4} alt="" />
                  </span>{" "}
                  ${formatCurrency(Flightdata.calculatedPrice)}
                </div>
              )}
              <div
                className="product-bottom"
                style={{ alignItems: "center", gap: "20px" }}
              >
                <OverlayTrigger
                  trigger={props.isDownArrow && "click"}
                  placement="top"
                  overlay={
                    <PopoverTop
                      className="popover-positioned-top"
                      content={Flightdata}
                      // fType={props.fType}
                    />
                  }
                  style={{ width: "fit-content", height: "fit-content" }}
                  rootClose
                >
                  <span
                    style={{
                      color: getEleAttribute("btnBackgroundColor", props.theme),
                      height: "fit-content",
                    }}
                  >
                    Details
                  </span>
                </OverlayTrigger>
                <Button
                  label={`Select Aircraft`}
                  onClick={(e) => {
                    props.selectFlight(Flightdata, props.index, props.fType);
                    props.resetTripSummary();
                  }}
                  fullWidth={false}
                  iconPosition="left"
                  size="small"
                  bgcolor={getEleAttribute(
                    "defaultBackgroundColor",
                    props.theme
                  )}
                  color={getEleAttribute("btnBackgroundColor", props.theme)}
                  borderColor={getEleAttribute(
                    "btnBackgroundColor",
                    props.theme
                  )}
                  isSelected={
                    props.selIndex === props.index &&
                    props.selectedFlightType === props.fType
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightListCard;
